@font-face {
  font-family: "Pretendard-Thin";
  font-weight: 300;
  src: url("font/Pretendard-Thin.otf") format("woff2");
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Light";
  font-weight: 400;
  src: url("font/Pretendard-Light.otf") format("woff2");
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Regular";
  font-weight: 500;
  src: url("font/Pretendard-Regular.otf") format("woff2");
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Medium";
  font-weight: 600;
  src: url("font/Pretendard-Medium.otf") format("woff2");
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Bold";
  font-weight: 700;
  src: url("font/Pretendard-Bold.otf") format("woff2");
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Pretendard-Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}