:root {
  --motion-black: #231815;
  --motion-gray-100: #F5F5F5;
  --motion-gray-200: #EBEBEB;
  --motion-gray-300: #E0E0E0;
  --motion-gray-400: #D6D6D6;
  --motion-gray-500: #CCCCCC;
  --motion-gray-600: #A3A3A3;
  --motion-gray-700: #7A7A7A;
  --motion-gray-800: #525252;
  --motion-gray-900: #292929;
  --motion-red-100: #FCE9EB;
  --motion-red-200: #F9D2D7;
  --motion-red-300: #F6BCC2;
  --motion-red-400: #E74C5D;
  --motion-red-500: #E11F35;
  --motion-red-600: #B4192A;
  --motion-green-100: #E1F9D2;
  --motion-green-500: #69E11F;
  --motion-blue-100: #E7F0FF;
  --motion-blue-500: #1F6DE1;
}

.App {
  text-align: center;
}
/* ag grid */
.selectable .ag-row-hover {
  cursor: pointer;
}
.ag-row-hover {
  --ag-row-hover-color: var(--motion-red-100);
}
.ant-modal .ag-row-hover {
  --ag-row-hover-color:: transparent;
}
.ant-spin-nested-loading {
  width: 100%
}
.ag-header-cell-label {
  justify-content: center
}
.ag-header-cell .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: fit-content;
}
.ag-cell-auto-height .ag-cell-value {
  line-height: 1.5rem;
  margin: .5rem 0;
}
.ag-cell-value.red-background {
  background-color: var(--motion-red-100);
}
.ag-cell-value.green-background {
  background-color: var(--motion-green-100);
}
/* modal */
.ant-modal {
  font-family: Pretendard-Regular;
}
.ant-modal .ant-modal-content {
  border-radius: 5px;
  padding: 25px 40px 30px;
  font-size: 16px;
}
.ant-modal-confirm .ant-modal-content {
  width: 536px
}
.ant-modal-confirm .ant-modal-confirm-paragraph {
  max-width: 100%
}
.ant-modal .ant-btn {
  border-radius: 5px;
  border: .8px solid var(--motion-gray-300);
  color: var(--motion-black);
  font-size: 16px;
  font-weight: 400;
  padding: 0 16px;
  height: 36px;
}
.ant-modal .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: var(--motion-red-400);
  border-color: var(--motion-red-400);
}
.ant-modal .ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
  color: var(--motion-red-600);
  border-color: var(--motion-red-600);
}
.ant-modal .ant-btn-primary {
  background-color: var(--motion-red-500);
  color: #FFFFFF;
  box-shadow: none;
}
.ant-modal .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: var(--motion-red-400);
}
.ant-modal .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active {
  background-color: var(--motion-red-600);
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn+.ant-btn {
  margin-left: 14px;
}
.ant-modal .ant-modal-footer,
.ant-modal .ant-modal-confirm-btns {
  margin-top: 30px;
}
/* popover */
.ant-popover .ant-popover-content {
  margin-bottom: 17px;
}
.ant-popover .ant-popover-inner { 
  box-shadow: none !important;
  border: .8px solid var(--motion-gray-300);
  border-radius: 5px;
  padding: 20px 20px 12px;
}
.ant-popconfirm .ant-popconfirm-message .ant-popconfirm-description {
  width: 260px;
  text-align: center;
  margin: 0 0 8px;
  font-size: 14px;
}
.ant-popconfirm-message-text {
  margin: 0;
}
.ant-popover .ant-btn.ant-btn-sm {
  font-size: 12px;
  width: 40px;
  height: 20px;
  margin-left: 8px;
}

/* input */
.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector,
.ant-picker-outlined:hover,
.ant-input-number-outlined:hover,
.ant-input-outlined:hover,
input:hover, 
textarea:hover {
  border-color: var(--motion-gray-700);
}
.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):focus .ant-select-selector,
.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):focus-within .ant-select-selector,
.ant-picker-outlined:focus,
.ant-picker-outlined:focus-within,
.ant-input-number-outlined:focus-within,
.ant-input-number-outlined:focus,
.ant-input-outlined:focus-within,
.ant-input-outlined:focus,
input:focus,
textarea:focus {
  border-color: var(--motion-black);
  box-shadow: none;
}
.ant-input-disabled,
.ant-picker-input>input::placeholder,
.ant-input-number-input-wrap>input::placeholder,
input::placeholder,
textarea::placeholder {
  color: var(--motion-gray-500);
}
.ant-input-status-error:focus {
  border: .8px solid var(--motion-red-500) !important;
}
.ant-form-item .ant-form-item-explain {
  color: var(--motion-red-500);
  margin: 5px 0 11px;
  font-weight: 400;
  font-size: 12px;
}
.ant-form-item-control {
  max-width: 480px;
}
.ant-space-compact .ant-btn {
  height: 45px;
}
.ant-form-item-label {
  max-width: 100%;
}
.ant-form-item-control-input .ant-input-number-affix-wrapper .ant-input-number-suffix {
  margin-inline-end: 30px;
}
.ant-btn:not(.ant-btn-icon-only)>.ant-btn-icon:not(:last-child) {
  margin-inline-end: 6px;
}
#excel_help {
  position: absolute;
  left: 9em;
}
/* view size 설정 */
@media all and (max-width: 576px) {
  .ant-layout-header {
    height: 56px;
    padding: 0 20px;
  }
  header.ant-layout-header a svg {
    width: 184px;
    height: 33px;
  }
  main.ant-layout-content {
    padding: 20px 20px 40px 20px;
    height: calc(100vh - 56px);
  }
  div.ag-theme-quartz {
    height: calc(100vh - 300px);
  }
  .ant-modal-confirm .ant-modal-content {
    width: calc(100% - 40px);
    margin: 0 20px;
    padding: 16px 10px;
  }
  .ant-modal-confirm .ant-modal-content svg {
    width: 30px;
    height: 30px;
  }
  .ant-modal-confirm .ant-modal-content h1 {
    margin: 16px !important;
    font-size: 16px !important;
  }
  .ant-modal-confirm-content div {
    font-size: 12px;
    line-height: 20px;
  }
  .ant-modal .ant-modal-confirm-btns {
    margin-top: 16px;
  }
}
